import React from 'react';
import { LinkButton } from '../../../components/simple';
import styles from './styles.module.scss';
import { mainPageLinks } from '../../../helpers/linkButtonsValues';
import { allDownloadAgreements, allStartedAgreements } from '../../../router/routes';
import useMultiLang from '../../../localization/useMultiLang';

const ActionsMenu = () => {
  const { t } = useMultiLang();

  return (
    <div className={styles.actionMenuContainer}>
      <div className={styles.defaultButtons}>
        {mainPageLinks.map((link) => (
          <LinkButton key={link.id} text={link.text} color={link.color} path={link.path} />
        ))}
      </div>
      {localStorage.getItem('role') === 'Admin' && (
        <div className={styles.adminButtons}>
          <LinkButton color="lightgreen" text={t('pings_agreement')} path={allDownloadAgreements} />
          <LinkButton color="lightblue" text={t('pings_ongoing_agreement')} path={allStartedAgreements} />
        </div>
      )}
    </div>
  );
};

export default ActionsMenu;
